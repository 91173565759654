<template>
    <button
        class="border-black border-2 font-bold text-black px-5 py-2 w-full hover:bg-black hover:text-yellow"
        @click="onClick"
    >
        Subscribe
        <font-awesome-icon
            v-if="loading"
            :icon="['fas', 'spinner']" 
            class="ml-1 animate-spin"
        />
    </button>
</template>
<script>
export default {
    name: 'SubscriptionButtonComponent',
    props: {
        loading: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    methods: {
        onClick() {
            this.$emit('click');
        }
    }
}
</script>