var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-10 flex flex-col items-center gap-y-6 md:gap-y-8 md:gap-x-20",class:{ 
        'md:flex-row-reverse': _vm.position % 2 === 0, 
        'md:flex-row': _vm.position % 2 === 1
    }},[_c('img',{staticClass:"md:w-1/2",class:{
            'md:pl-8': _vm.position % 2 === 0, 
            'md:pr-8': _vm.position % 2 === 1
        },attrs:{"src":_vm.image}}),_c('div',{staticClass:"flex flex-col gap-y-6 md:w-1/2 md:gap-y-10 md:text-lg"},[_c('h2',{staticClass:"font-bold capitalize text-xl md:text-2xl"},[_vm._v(" "+_vm._s(_vm.titlePrev)+" "),_c('span',{staticClass:"text-yellow"},[_vm._v(_vm._s(_vm.titleHigh))]),_vm._v(" "+_vm._s(_vm.titlePost)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.content)+" ")]),_c('a',{staticClass:"font-bold capitalize text-yellow",attrs:{"href":_vm.link,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.linkText)+" "),_c('font-awesome-icon',{staticClass:"ml-2 text-lg font-semibold",attrs:{"icon":['fas', 'angle-right']}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }