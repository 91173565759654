<template>
    <div class="flex flex-col gap-y-4 md:grid md:grid-cols-2">
        <div class="flex flex-col gap-y-4 text-sm">
            <div class="flex flex-row gap-x-3 items-center md:items-start">
                <span
                    class="text-xs md:text-base"
                >
                    Company:
                </span>
                <span
                    class="font-semibold uppercase"
                >
                    {{ product.company.name }}
                </span>
            </div>
            <div class="flex flex-row gap-x-3 items-center">
                <span
                    class="text-xs md:text-base"
                >
                    Tested by ICE: 
                </span>
                <div
                    class="font-semibold"
                >
                    <font-awesome-icon 
                        v-for="(startType, index) in stars"
                        :key="index"
                        
                        :icon="['fas', startType === 1 ? 'star': 'star-half']"
                        class="text-yellow"
                    />
                </div>
                {{ product.rating }} / {{ maxRating }}
            </div>
        </div>
        <div class="flex flex-col gap-y-8 md:items-end md:gap-y-4">
            <div class="flex flex-row items-center gap-x-3">
                <div class="text-xs">
                    Price
                </div>
                <div
                    class="text-xl font-bold"
                >
                    {{ product.price }}
                </div>
            </div>
            <div class="flex justify-center">
                <ContactButton
                    link="mailto:info@ibizace.com"
                    text="Get in contact"
                    :new-tab="false"
                    dense
                />
            </div>
        </div>
    </div>
</template>
<script>
import ContactButton from '@/components/shared/contact_button.vue';

export default {
    name: 'WebshopItemContactComponent',
    components: {
        ContactButton
    },
    props: {
        product: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            maxRating: 5
        };
    },
    computed: {
        stars() {
            const roundRating = Math.floor(this.product.rating); 
            let result = Array(roundRating);
            result.fill(1);
            if (roundRating !== this.product.rating) {
                result.push(0);
            }
            return result;
        }
    }
};
</script>