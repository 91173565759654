<template>
    <div class="flex flex-col h-auto">
        <HeroComponent
            :background-image="heroImage"
            class="px-10 pt-30 pb-44 bg-bottom md:bg-cover"
        >
            <slot>
                <div class="flex flex-col gap-y-12 md:w-105 md:mx-auto">
                    <h1 class="font-bold text-4xl text-center md:font-black md:text-5xl">
                        Join <span class="text-yellow">our club</span> and let's grow together
                    </h1>
                    <h2
                        class="font-medium text-sm text-center md:text-sm md:font-semibold"
                    >
                        Ibiza Center for Entrepreneurship (ICE) is the central place for 
                        anything related to entrepreneurship on the island, internationally 
                        and online.
                    </h2>
                    <div class="flex justify-center py-4">
                        <a 
                            href="https://chat.whatsapp.com/IcT3bz8z2ee72pkOiSzdec"
                            target="_blank"
                            class="font-bold text-lg p-4 bg-yellow text-black border-2 border-yellow hover:border-black md:px-10"
                        >
                            Join now
                        </a>
                    </div>
                </div>
            </slot>
        </HeroComponent>
        <HomeStatsComponent />
        <HomeJoinComponent />
        <HomeServicesComponent />
        <HomeHeroMapComponent />
        <HomeIndustriesComponent />
        <HomeBecomeMemberComponent />
        <HomeCollaboratorsComponent />
        <TestimonialsComponent />
        <SubscriptionComponent />
    </div>
</template>
<script>
import HeroComponent from '@/components/shared/hero.vue';
import HomeStatsComponent from '@/components/home/stats.vue';
import HomeJoinComponent from '@/components/home/join.vue';
import HomeServicesComponent from '@/components/home/services.vue';
import HomeHeroMapComponent from '@/components/home/hero_map.vue';
import HomeIndustriesComponent from '@/components/home/industries.vue';
import HomeBecomeMemberComponent from '@/components/home/become_member.vue';
import HomeCollaboratorsComponent from '@/components/home/collaborators.vue';
import TestimonialsComponent from '@/components/shared/testimonials.vue';
import SubscriptionComponent from '@/components/subscription';

export default {
    name: 'HomePage',
    components: {
        HeroComponent,
        HomeStatsComponent,
        HomeJoinComponent,
        HomeServicesComponent,
        HomeHeroMapComponent,
        HomeIndustriesComponent,
        HomeBecomeMemberComponent,
        HomeCollaboratorsComponent,
        TestimonialsComponent,
        SubscriptionComponent
    },
    computed: {
        heroImage() {
            const imagePath = require ('@/assets/img/home/home-hero.jpg');
            return {backgroundImage: ` linear-gradient(rgba(0, 0, 255, 0.1), rgba(0, 0, 255, 0.1)), url(${imagePath})`, 'background-position-x': 'right', 'background-position-y': 'bottom'};
        }
    },
    metaInfo() {
        const baseUrl = process.env.VUE_APP_BASE_URL;
        return {
            title: 'We make companies more profitable - Ibiza Center for Entrepreneurship (ICE) is the central place for anything related to entrepreneurship on the island, internationally and online.',
            titleTemplate: null,
            meta: [
                {
                    name: 'description',
                    content: 'Ibiza Center for Entrepreneurship (ICE) is the central place for anything related to entrepreneurship on the island, internationally and online.'
                },
                {
                    property: 'og:title',
                    content: 'We make companies more profitable - Ibiza Center for Entrepreneurship (ICE) is the central place for anything related to entrepreneurship on the island, internationally and online.'
                },
                {
                    property: 'og:url',
                    content: baseUrl
                },
                {
                    property: 'og:image',
                    content: `${baseUrl}/img/home/home-hero.jpg`
                }    
            ],
            link: [
                {
                    rel: 'canonical',
                    href: baseUrl
                }
            ]
        };
    }
};
</script>