<template>
    <div class="grid grid-flow-col auto-cols-max items-center gap-x-5">
        <img 
            :src="logo"
            alt="We make companies more profitable"
        />
        <div
            class="font-semibold text-black text-2xl flex flex-col gap-y-2"
        >
            <p>Don't miss out.</p>
            <p>Subscribe to our newsletter:</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SubcriptionHeaderDesktopComponent',
    computed: {
        logo() {
            return require('@/assets/img/logo.png');
        }
    }
};
</script>