<template>
    <div
        class="flex gap-x-6"
    >
        <img 
            :src="logo"
            alt="We make companies more profitable"
            class="w-16"
        />
        <div
            class="flex flex-col gap-y-2"
        >
            <a
                href="mailto:info@ibizace.com"
                class="font-extrabold"
            >
                info@ibizace.com
            </a>
            <div
                class="flex flex-row gap-x-2"
            >
                <a 
                    href="https://www.linkedin.com/company/ibiza-center-for-entrepreneurship" 
                    target="_blank"
                    class="p-1"
                >
                    <img 
                        :src="linkedinLogo" 
                        title="Ibizace Linkedin Page"
                        alt="Ibiza Center For Entrepreneurship Linkedin Page"
                        class="w-6"
                    />
                </a>
                <a 
                    href="https://t.me/ibizabusiness"
                    target="_blank"
                    class="p-1"
                >
                    <img 
                        :src="telegramLogo"
                        title="Ibizace Telegram Group"
                        alt="Ibiza Center For Entrepreneurship Telegram Group"
                        class="w-6"
                    />
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SubcriptionHeaderMobileComponent',
    computed: {
        logo() {
            return require('@/assets/img/logo.png');
        },
        linkedinLogo() {
            return require ('@/assets/img/linkedin-black.png');
        },
        telegramLogo() {
            return require ('@/assets/img/telegram-black.png');
        }
    }
};
</script>