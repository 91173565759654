<template>
    <section class="w-full bg-darkBlack">
        <div class="py-10 gap-y-10 flex flex-col md:container md:mx-auto md:px-10 md:py-20 md:gap-y-20">
            <h2 class="text-yellow font-bold text-lg text-center md:text-4xl">
                Focus Industries
            </h2>
            <SliderComponent
                container-id="industriesSlider"
                :items="items"
                :show-controls="true"
                :show-numbers="false"
                :hover-highlight="true"
                :auto-play="false"
                class="w-full px-5"
            >
                <template #card="item">
                    <div
                        class="flex flex-col items-center gap-y-5"
                    >
                        <img 
                            :src="imagePath(item)"
                            :alt="item.text"
                            class="w-full object-fill overflow-y-hidden max-h-32 md:max-h-48"
                        />
                        <span 
                            class="text-xs uppercase font-normal text-center md:text-xl"
                        >
                            {{ item.text }}
                        </span>
                    </div>
                </template>
            </SliderComponent>
        </div>
    </section>
</template>
<script>
import SliderComponent from '@/components/shared/slider.vue';

export default {
    name: 'HomeIndustriesComponent',
    components: {
        SliderComponent
    },
    data() {
        return {
            items: require('@/assets/focus-industries.json')
        };
    },
    methods: {
        imagePath(item) {
            return `${process.env.VUE_APP_MEDIA_PATH}industries/${item.image}`;
        }
    }
};
</script>