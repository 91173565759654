var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"w-full flex"},[_c('li',{staticClass:"w-1/2 py-1 cursor-pointer border-b-2 font-medium text-sm md:text-base",class:{
            'border-white text-white': _vm.activeTab === 0, 
            'text-gray-400 border-gray-500': _vm.activeTab === 1
        },on:{"click":function($event){return _vm.change(0)}}},[_vm._v(" Product Description ")]),_c('li',{staticClass:"w-1/2 py-1 cursor-pointer border-b-2 font-medium text-sm md:text-base",class:{
            'border-white text-white': _vm.activeTab === 1, 
            'text-gray-400 border-gray-500': _vm.activeTab === 0
        },on:{"click":function($event){return _vm.change(1)}}},[_vm._v(" Company Description ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }