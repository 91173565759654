<template>
    <section 
        class="w-full bg-yellow text-black px-6 py-8"
    >
        <div class="hidden container mx-auto w-full md:grid md:grid-cols-2 py-20">
            <SubcriptionHeaderDesktopComponent />

            <div class="flex flex-col">
                <div class="grid grid-flow-col gap-6 items-end">
                    <div class="text-base font-semibold">
                        <SubscriptionInputComponent
                            type="text"
                            name="name"
                            placeholder="Your name"
                            :loading="loading"
                            class="py-2 pl-2 mb-4"
                            @change="(evt) => name = evt.target.value"
                            @focus="clearMessage"
                        />
                        <SubscriptionInputComponent
                            type="email"
                            name="email"
                            placeholder="Your email"
                            :loading="loading"
                            class="py-2 pl-2"
                            @change="(evt) => email = evt.target.value"
                            @focus="clearMessage"
                        />
                    </div>
                    <SubscriptionButtonComponent
                        :loading="loading"
                        @click="send"
                    />
                </div>
                <SubscriptionMessageComponent
                    :type="message.type"
                    :text="message.text"
                    class="py-2 h-4"
                />
            </div>
        </div>

        <div class="container mx-auto flex flex-col gap-y-8 md:hidden">
            <SubcriptionHeaderMobileComponent />
            <div class="flex flex-col justify-items-center gap-y-4">
                <h3 class="text-xl text-center py-3 font-black">
                    Subscribe to our newsletter:
                </h3>
                <SubscriptionInputComponent
                    type="text"
                    name="name"
                    placeholder="Your name"
                    :loading="loading"
                    class="px-4 py-1 text-sm font-medium"
                    @change="(evt) => name = evt.target.value"
                    @focus="clearMessage"
                />
                <SubscriptionInputComponent
                    type="email"
                    name="email"
                    placeholder="Your email"
                    :loading="loading"
                    class="px-2 text-sm font-medium"
                    @change="(evt) => email = evt.target.value"
                    @focus="clearMessage"
                />
                <SubscriptionMessageComponent
                    :type="message.type"
                    :text="message.text"
                    class="h-4"
                />
                <div class="items-center py-4 mx-auto">
                    <SubscriptionButtonComponent
                        :loading="loading"
                        @click="send"
                    />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from "axios";

import SubcriptionHeaderDesktopComponent from './desktop.vue';
import SubcriptionHeaderMobileComponent from './mobile.vue';
import SubscriptionButtonComponent from './button.vue';
import SubscriptionInputComponent from './input.vue';
import SubscriptionMessageComponent from './message.vue';

export default {
    name: 'SubscriptionComponent',
    components: {
        SubcriptionHeaderDesktopComponent,
        SubcriptionHeaderMobileComponent,
        SubscriptionButtonComponent,
        SubscriptionInputComponent,
        SubscriptionMessageComponent
    },
    data() {
        return {
            loading: false,
            email: '',
            name: '',
            message: {
                type: 0,
                text: null
            }
        };
    },
    methods: {
        async send() {
            if (!this._validation()) return;

            this.loading = true;

            const headers = { 'Content-Type': 'application/json' };
            const url =`${process.env.VUE_APP_BASE_API_URL}/subscribe`;
            const data = JSON.stringify({
                email: this.email.trim(),
                name: this.name.trim()
            });

            axios({ method: 'post', url, data, headers})
                .then(() => {
                    this._showSuccessMessage('Subscription completed!');

                }).catch((error) => {
                    this._showErrorMessage(error.response.data.error);

                }).finally(() => {
                    this.loading = false;
                });
        },
        clearMessage() {
            this._showMessage(0, null);
        },
        _validation() {
            if (!this.name.trim()) {
                return this._showErrorMessage('Name required');
            }

            if (!this.email.trim()) {
                return this._showErrorMessage('Email required');
            }

            const emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
            if (!emailCheck.test(this.email)) {
                return this._showErrorMessage('Email invalid');
            }

            return true;
        },
        _showErrorMessage(text) {
            this._showMessage(-1, text);
            return false;
        },
        _showSuccessMessage(text) {
            this._showMessage(1, text);
            return true;
        },
        _showMessage(type, text) {
            this.message.type = type;
            this.message.text = text;
        }
    }
};
</script>