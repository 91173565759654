<template>
    <section
        class="w-full bg-darkBlack py-10"
    >
        <div class="container mx-auto flex flex-col gap-y-10 text-white px-6 pb-8">
            <JoinBlockComponent
                v-for="i in items"
                :key="i.id"
                :position="i.position"
                :title-prev="i.titlePrev"
                :title-post="i.titlePost"
                :title-high="i.titleHigh"
                :content="i.content"
                :link="i.link"
                :link-text="i.linkText"
                :image-path="i.imagePath"
            />
        </div>
    </section>
</template>
<script>
import JoinBlockComponent from '@/components/home/join_block.vue';
export default {
    name: 'HomeBecomeMemberComponent',
    components: {
        JoinBlockComponent
    },
    data() {
        return {
            items: [
                {
                    id: 'become-club-member',
                    position: 2,
                    titlePrev: 'Become a',
                    titleHigh: 'club member',
                    titlePost: 'and increase your profitability.',
                    content: 'Club members get added on our online platform and offline network to get more exposure, leads and clients. Together with our club members we will help to increase your profitability.',
                    link: 'https://chat.whatsapp.com/IcT3bz8z2ee72pkOiSzdec',
                    linkText: 'Join now',
                    imagePath: 'img/home/join-our-club-3.png'
                },
                {
                    id: 'become-club-sponsor',
                    position: 1,
                    titlePrev: 'Become a',
                    titleHigh: 'sponsor',
                    titlePost: 'and grow together with us',
                    content: "We have created an ecosystem with decision makers across the globe. Get to know us and let’s create your growth plan.",
                    link: 'mailto:info@ibizace.com',
                    linkText: 'Get in touch',
                    imagePath: 'img/home/join-our-club-2.png'
                }
            ]
        }
    }
};
</script>