<template>
    <div 
        class="flex-row-reverse px-2 text-sm"
        :class="{'text-red-600': type === -1, 'text-green-600': type === 1}"
    >
        {{ text }}
    </div>
</template>
<script>
export default {
    name: 'SubscriptionMessageComponent',
    props: {
        type: {
            type: Number,
            required: true,
            default: 0
        },
        text: {
            type: String,
            required: false, 
            default: ''
        }
    }
};
</script>