<template>
    <div class="flex flex-col gap-y-6">
        <h3
            class="font-semibold"
        >
            Contact Person
        </h3>
        <div class="flex flex-col gap-y-4 md:flex-row md:gap-x-8 md:gap-y-0">
            <div class="md:w-1/4">
                <img
                    :src="imagePath(product.author.image)"
                    class="object-cover h-48 w-48"
                />
            </div>
            <div class="md:w-2/4 grid grid-rows-3 gap-y-4">
                <span
                    class="uppercase font-semibold text-sm md:text-base"
                >
                    {{ product.author.name }}
                </span>
                <div 
                    v-if="product.author.email"
                    class="flex flex-col gap-y-1 md:gap-y-2"
                >
                    <span
                        class="text-xs md:text-sm"
                    >
                        Email contact person
                    </span>
                    <a 
                        :href="`mailto:${product.author.email}`"
                        target="_blank"
                        class="font-semibold text-sm md:text-base"
                    >
                        {{ product.author.email }}
                    </a>
                </div>
                <div 
                    v-if="product.author.phone"
                    class="flex flex-col gap-y-1 md:gap-y-2"
                >
                    <span
                        class="text-xs md:text-sm"
                    >
                        Phone contact person
                    </span>
                    <span class="font-semibold text-sm md:text-base">
                        {{ product.author.phone }}
                    </span>
                </div>
            </div>
            <div class="md:w-1/4 py-8 md:py-0">
                <ContactButton
                    link="mailto:info@ibizace.com"
                    text="Get in contact"
                    :new-tab="false"
                />
            </div>
        </div>
    </div>
</template>
<script>
import ContactButton from '@/components/shared/contact_button.vue';

export default {
    name: 'WebshopItemCompanyContactComponent',
    components: {
        ContactButton
    },
    props: {
        product: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    methods: {
        imagePath(path) {
            return path ? `${process.env.VUE_APP_MEDIA_PATH}products/${path}`: require(`@/assets/img/no-user.png`);

        }
    }
};
</script>