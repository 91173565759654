<template>
    <div
        class="flex items-center justify-end text-white"
    >
        <label class="text-sm font-medium">
            Short by
        </label>

        <div class="text-sm">
            <div 
                class="relative"
            >
                <div
                    v-click-outside="closeDropdown"
                    class="inline-block w-full cursor-pointer py-2 px-4"
                >
                    <div
                        class="cursor-pointer hover:border hover:border-yellow"
                        @click="openDropdown"
                    >
                        {{ selectedText }}
                        <font-awesome-icon 
                            :icon="['fas', 'angle-down']" 
                            class="text-xs font-semibold ml-2"
                        />
                    </div>
                </div>

                <div
                    v-show="isOpen"
                    class="absolute mt-2 w-full bg-black min-w-max py-2"
                >
                    <ul
                        tabindex="-1"
                        class="overflow-auto cursor-pointer"
                    >
                        <li
                            v-for="(d, index) in items"
                            v-show="!isSelected(index)"
                            :key="index" 
                            class="py-2 px-4 hover:text-black hover:bg-yellow min-w-max"
                            @click="change(index)"
                        >
                            {{ d.text }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';

export default {
    name: 'WebshopShortComponent',
    directives: {
        ClickOutside
    },
    props: {
        selected: {
            type: Number,
            required: true,
            default: 0
        },
        items: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            'isOpen' : false
        }
    },
    computed: {
        selectedText() {
            return this.items[this.selected].text;
        } 
    },
    methods: {
        isSelected(index) {
            return this.selected === index;
        },
        openDropdown() {
            this.isOpen = true;
        },
        closeDropdown() {
            this.isOpen = false;
        },
        change(index) {
            this.isOpen = false;
            this.$emit('change', index);
        }
    }
};
</script>