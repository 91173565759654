<template>
    <a 
        :href="link"
        :target="target"
        class="py-4 font-bold bg-yellow text-black border-2 border-yellow hover:bg-black hover:text-yellow"
        :class="{'px-6 text-lg': !dense, 'px-4 text-base': dense}"
    >
        {{ text }}
    </a>
</template>
<script>
export default {
    name: 'ContactButton',
    props: {
        text: {
            type: String,
            required: true,
            default: ''
        },
        link: {
            type: String,
            required: true,
            default: ''
        },
        newTab: {
            type: Boolean,
            required: true,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        target() {
            return this.newTab ? '_blank' : '';
        }
    }
};
</script>