import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import VueCookie from 'vue-cookie';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueGtag from 'vue-gtag'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faChevronDown, faChevronUp, faXmark, faAngleLeft, faAngleRight, faAngleDown,
    faEnvelope, faPhone,
    faStar, faStarHalf,
    faBars, faSliders,
    faSpinner
} from '@fortawesome/free-solid-svg-icons';

import './style.css';
import App from './App.vue';
import routes from './routes';

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueCookie);
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: 'marker'
    }
});

library.add(faChevronDown);
library.add(faChevronUp);
library.add(faXmark);
library.add(faAngleRight);
library.add(faAngleLeft);
library.add(faAngleDown);
library.add(faEnvelope);
library.add(faPhone);
library.add(faStar);
library.add(faStarHalf);
library.add(faBars);
library.add(faSliders);
library.add(faSpinner);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        const container = document.getElementById('app');
        if (container) container.scrollIntoView({ behavior: 'smooth' });
    }
});

const gtagConfiguration = {
    config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_CODE,
        params: {
            enabled: process.env.NODE_ENV === 'production'
        }
    }
};

Vue.use(VueGtag, gtagConfiguration, router);

new Vue({
    router,
    render: (h) => h(App)
}).$mount("#app");
