<template>
    <div
        class="grid gap-7 p-8 md:p-4 md:grid-cols-2 text-white"
    >
        <div
            v-for="i in items"
            :key="i.id"
            class="bg-black flex flex-col cursor-pointer border border-black hover:border-yellow"
            @click="showItem(i)"
        >
            <div class="h-40">
                <img 
                    :src="imagePath(i)"
                    class="object-cover w-full h-40"
                />
            </div>
            <div class="p-4">
                <h3 class="font-semibold md:text-lg ">
                    {{ i.title }}
                </h3>
                <div class="mt-1 text-xs md:text-sm">
                    by {{ i.company.name }}
                </div>
                <div class="mt-6 hidden md:flex">
                    {{ i.price }}
                </div>
                <div class="mt-6 grid grid-cols-2 text-sm font-bold md:hidden">
                    <span>
                        {{ i.price }}
                    </span>
                    <div
                        class="text-right items-center text-yellow"
                        @click="showItem(i)"
                    >
                        More
                        <font-awesome-icon 
                            :icon="['fas', 'angle-right']" 
                            class="ml-1"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'WebshopGridComponent',
    props: {
        items: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        imagePath(item) {
            return `${process.env.VUE_APP_MEDIA_PATH}products/${item.image}`;
        },
        showItem(i) {
            this.$router.push({ name: 'shop-item', params: { productSlug: i.slug } })
        }
    }
};
</script>