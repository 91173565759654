<template>
    <div class="flex flex-col h-auto bg-darkBlack text-white">
        <HeroComponent
            :background-image="heroImage"
        >
            <slot>
                <h1
                    class="w-full text-3xl font-extrabold text-center"
                >
                    Connect with our 
                    <span class="text-yellow">Club Members</span>
                    and grow together
                </h1>
                <p class="w-full text-sm text-center my-8 font-semibold">
                    Found something you like? Get in contact!
                </p>
            </slot>
        </HeroComponent>

        <div class="container mx-auto py-2">
            <BreadcrumbComponent 
                :items="breadcrumb"
                class="w-full px-4 md:px-0"
            />
            <div
                class="flex flex-col p-10"
            >
                <h1
                    class="w-full font-semibold mb-6 text-lg md:text-3xl md:mb-10 "
                >
                    {{ product.title }}
                </h1>
                <WebshopItemContactComponent
                    :product="product"
                    class="mb-10"
                />
                <WebshopItemDescriptionComponent
                    :product="product"
                />
                <WebshopItemCompanyContactComponent
                    :product="product"
                    class="mt-8"
                />
                <router-link 
                    :to="{ name: 'shop' }"
                    class="block font-bold text-sm md:hidden pt-6 items-center "
                >
                    <font-awesome-icon 
                        :icon="['fas', 'angle-left']" 
                        class="mr-2 md:mr-4"
                    />
                    Go back to Shop
                </router-link>
            </div>
            <WebshopJoinComponent />
        </div>
        <SubscriptionComponent />
    </div>
</template>
<script>
import BreadcrumbComponent from '@/components/shared/breadcrumb.vue';
import HeroComponent from '@/components/shared/hero.vue';
import WebshopItemContactComponent from '@/components/webshop_item/contact.vue';
import WebshopItemDescriptionComponent from '@/components/webshop_item/description';
import WebshopItemCompanyContactComponent from '@/components/webshop_item/company_contact.vue';
import WebshopJoinComponent from '@/components/webshop/join.vue';
import SubscriptionComponent from '@/components/subscription';

export default {
    name: 'WebshopItemPage',
    components: {
        BreadcrumbComponent,
        HeroComponent,
        WebshopItemContactComponent,
        WebshopItemDescriptionComponent,
        WebshopItemCompanyContactComponent,
        WebshopJoinComponent,
        SubscriptionComponent
    },
    data() {
        return {
            slug: null,
            products: [],
            breadcrumb: [
                {
                    name: 'Home',
                    link: 'home'
                },
                {
                    name: 'Shop',
                    link: 'shop'
                }
            ],
            product: null
        };
    },
    computed: {
        heroImage() {
            const imagePath = require ('@/assets/img/webshop/webshop-hero.png');
            return {backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4)), url(${imagePath})`};
        }
    },
    created() {
        this.products = require('@/assets/products.json');
        this.slug = this.$route.params.productSlug;
        this.product = this.products.filter((p) => p.slug === this.slug)[0];
        if (!this.product) {
            this.$router.push({name: 'not_found'});
        } else {
            this.breadcrumb.push({
                name: this.product.title
            });
        }
    },
    metaInfo() {
        const baseUrl = process.env.VUE_APP_BASE_URL;
        return {
            title: `${this.product.title} - We make companies more profitable.`,
            meta: [
                {
                    name: 'description',
                    content: this.product.description
                },
                {
                    property: 'og:title',
                    content: `${this.product.title} - We make companies more profitable.`,
                },
                {
                    property: 'og:url',
                    content: baseUrl
                },
                {
                    property: 'og:image',
                    content: `${baseUrl}${this.product.image}`
                }    
            ],
            link: [
                {
                    rel: 'canonical',
                    href: `${baseUrl}${this.slug}`
                }
            ]
        };
    }
};
</script>