<template>
    <section class="w-full">
        <div class="container mx-auto px-10 py-16 flex flex-col items-center gap-y-10 md:gap-y-20 md:py-20">
            <h2 class="text-center text-xl font-bold md:text-5xl md:font-black">
                Services we provide
            </h2>
            <div 
                class="flex flex-col gap-y-3 items-center w-full md:w-2/3 md:gap-y-10"
            >
                <div
                    v-for="(s, index) in services"
                    :key="index"
                    class="flex w-full font-medium hover:w-80 transition-all ease-out duration-500 delay-150 text-sm md:text-2xl"
                >
                    <div class="flex-none block">
                        {{ s }}
                    </div>
                    <div class="ml-3 py-2 flex-1">
                        <span class="bg-yellow h-3 block">&nbsp;&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'HomeServicesComponent',
    data() {
        return {
            services: [
                'START-UP SERVICES', 'SOFTWARE DEVELOPMENT',
                'MASTERMIND GROUPS', 'FUNDING', 
                'DIGITAL PRODUCT DEVELOPMENT', 'CO-CREATION', 
                'INTERNATIONAL BUSINESS', 'DIGITAL INNOVATION'
            ]
        };
    }
}
</script>