<template>
    <section
        class="w-full bg-darkBlack"
    >
        <div class="container mx-auto py-28 flex flex-col gap-y-20 md:grid md:grid-cols-3 md:gap-x-48">
            <div
                v-for="i in stats"
                :key="i.index"
                class="w-full flex flex-col items-center gap-y-2 md:gap-y-6"
            >
                <div class="text-yellow text-6xl font-semibold text-center md:text-8xl md:font-bold">
                    <div v-if="i.type === 1">
                        {{ i.value }}+
                    </div>
                    <div v-else>
                        {{ i.value }}
                    </div>
                </div>
                <div class="text-white uppercase text-center  md:leading-10 md:text-xl md:font-normal">
                    {{ i.text }}
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'HomeStatsComponent',
    data() {
        return {
            stats: [
                {
                    index: 1,
                    text: 'club members',
                    value: 0,
                    maxValue: 407,
                    type: 1
                },
                {
                    index: 2,
                    text: 'active countries',
                    value: 0,
                    maxValue: 45,
                    type: 1
                },
                {
                    index: 3,
                    text: 'startup ecosystem',
                    value: 1,
                    type: 2
                }
            ]
        };
    },
    mounted() {
        this.stats.filter(i => i.type === 1).forEach((i) => {
            let intervalId = window.setInterval(() => {
                i.value += 1;
                if (i.value === i.maxValue) {
                    window.clearInterval(intervalId);
                    intervalId = null;
                }
            }, 5);
        });
    }
};
</script>