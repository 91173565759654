<template>
    <section
        class="w-full bg-darkBlack text-white py-8 gap-14 px-2 flex flex-col md:grid md:grid-cols-2 md:px-0 md:py-32"
    >
        <div 
            v-for="i in items"
            :key="i.id"
            class="items-center px-6 w-full md:border-l-2 md:border-yellow "
        >
            <h1
                class="mb-4 md:font-semibold md:text-2xl md:mb-10"
            >
                {{ i.title }}
            </h1>
            <p class="mb-2 text-xs">
                {{ i.description1 }}
            </p>
            <p class="text-sm mb-6">
                {{ i.description2 }}
            </p>
            <a
                :href="i.link.link"
                target="_blank"
                class="text-yellow font-semibold text-sm md:text-base"
            >
                {{ i.link.text }}
            </a>
        </div>
    </section>
</template>
<script>
export default {
    name: 'WebshopJoinComponent',
    data() {
        return {
            items: [
                {
                    id: 0,
                    title: 'Are you searching for customers?',
                    description1: 'The hardest part of any services-based business is finding a reliable source of prosprective customers that need your solutions.',
                    description2: 'We\'ll help you establish a customer base, sell services online and grow your business.',
                    link: {
                        text: 'Get in touch',
                        link: 'https://chat.whatsapp.com/IcT3bz8z2ee72pkOiSzdec'
                    }
                },
                {
                    id: 1,
                    title: 'Do you need professionals for work?',
                    description1: 'Business growth requires a lot of time, effort, funding and workforce.',
                    description2: 'We make employment opportunities accesible to everyone, everywhere and deliver a connected and supportive experience for all. Look through the list of services we can provide you with.',
                    link: {
                        text: 'Join our club',
                        link: 'https://www.f6s.com/ibiza-center-for-entrepreneurship-2023'
                    }
                }
            ]
        };
    }
};
</script>