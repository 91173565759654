<template>
    <div class="container mx-auto p-10 text-lg text-gray-600">
        <h1 class="text-2xl font-bold text-black mt-8">
            Intelectual property rights
        </h1>
        <p class="text-xs font-bold italic mt-2">
            Effective date: December 1, 2020
        </p>
        <p class="mt-4">
            Other than content you own, which you may have opted to include on this Website, under these Terms, Ibiza Center for Entrepreneurship own all rights to the intellectual property and material contained 
            in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.
        </p>						
        <h2 class="mt-6 font-semibold">
            Restrictions
        </h2>	
        <p class="mt-4">
            You are expressly and emphatically restricted from all the following:
        </p>
        <ul class="text-base list-disc ml-8 mt-4">
            <li>publishing any Website material in any media;</li>
            <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
            <li>publicly performing and/or showing any Website material;</li>
            <li>using this Website in any way that is, or may be, damaging to this Website;</li>
            <li>using this Website in any way that impacts user access to this Website;</li>
            <li>using this Website contrary to applicable laws and regulations, or in a way that causes, or</li>
            <li>may cause, harm to the Website, or to any person or business entity;</li>
            <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;</li>
            <li>using this Website to engage in any advertising or marketing;</li>
            <li>
                Certain areas of this Website are restricted from access by you and Ibiza Center for Entrepreneurship may further restrict access by you to any areas of this website, at any time, in its sole and absolute discretion. 
                Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such information.
            </li>
        </ul>
        <h2 class="mt-6 font-semibold">
            Your content
        </h2>		
        <p class="mt-4">
            Your Content must be your own and must not be infringing on any third party’s rights. Ibiza Center for Entrepreneurship reserves the right to remove any of Your Content from this Website at any time, and for any reason, without notice.
        </p>						
        <h2 class="mt-6 font-semibold">
            Limitation of liability
        </h2>		
        <p class="mt-4">
            Ibiza Center for Entrepreneurship is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and
            conditions governing use of this Website.
        </p>						
        <h2 class="mt-6 font-semibold">
            Variation of terms
        </h2>						
        <p class="mt-4">
            Ibiza Center for Entrepreneurship is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms 
            and conditions governing use of this Website.
        </p>						
        <h2 class="mt-6 font-semibold">
            Assignment
        </h2>
        <p class="mt-4">
            Ibiza Center for Entrepreneurship shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required.
        </p>
        <h2 class="mt-6 font-semibold">
            Entire agreement
        </h2>
        <p class="mt-4">
            These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Ibiza Center for Entrepreneurship and you in relation to your use of this Website, 
            and supersede all prior agreements and understandings with respect to the same.
        </p>
        <h2 class="mt-6 font-semibold">
            Contact us
        </h2>		
        <p class="mt-4">
            If you have any questions about this Terms &amp; Conditions, please contact us: <span class="underline">info@ibizace.com</span>
        </p>		
    </div>
</template>
<script>
export default {
    name: 'TACPage'
};
</script>