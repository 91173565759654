<template>
    <div 
        class="pb-10 flex flex-col items-center gap-y-6 md:gap-y-8 md:gap-x-20"
        :class="{ 
            'md:flex-row-reverse': position % 2 === 0, 
            'md:flex-row': position % 2 === 1
        }"
    >
        <img 
            :src="image" 
            class="md:w-1/2"
            :class="{
                'md:pl-8': position % 2 === 0, 
                'md:pr-8': position % 2 === 1
            }"
        />
        <div 
            class="flex flex-col gap-y-6 md:w-1/2 md:gap-y-10 md:text-lg "
        >
            <h2 class="font-bold capitalize text-xl md:text-2xl">
                {{ titlePrev }} <span class="text-yellow">{{ titleHigh }}</span> {{ titlePost }}
            </h2>
            <p>
                {{ content }}
            </p>
            <a 
                :href="link"
                class="font-bold capitalize text-yellow"
                target="_blank"
            >
                {{ linkText }}
                <font-awesome-icon 
                    :icon="['fas', 'angle-right']" 
                    class="ml-2 text-lg font-semibold"
                />
            </a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'JoinBlockComponent',
    props: {
        position: {
            type: Number,
            required: true,
            default: 1
        },
        titlePrev: {
            type: String,
            required: true,
            default: ''
        },
        titlePost: {
            type: String,
            required: true,
            default: ''
        },
        titleHigh: {
            type: String,
            required: true,
            default: ''
        },
        content: {
            type: String,
            required: true,
            default: ''
        },
        link: {
            type: String,
            required: true,
            default: ''
        },
        linkText: {
            type: String,
            required: true,
            default: ''
        },
        imagePath: {
            type: String,
            required: true,
            default: ''
        }
    },
    computed: {
        image() {
            return require (`@/assets/${this.imagePath}`);
        }
    }
}
</script>