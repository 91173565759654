<template>
    <div
        class="text-white absolute top-0 left-0 right-0 bottom-0 z-50"
    >
        <div class="flex flex-col w-full h-screen">
            <div 
                class="w-full bg-darkBlack flex items-center pt-4 pb-2 px-6"
            >
                <div 
                    class="text-white mr-4"
                >
                    <font-awesome-icon 
                        :icon="['fas', 'xmark']"
                        class="text-2xl"
                        @click="close"
                    />
                </div>
                <img 
                    :src="logo"
                    alt="We make companies more profitable"
                    class="object-cover h-16 md:h-auto"
                />
            </div>
            <div class="w-full bg-black px-8 py-6 flex flex-col">
                <div
                    class="pb-4 text-xl font-semibold"
                >
                    Filters
                </div>
                <div>
                    <div
                        v-for="(values, key) in items"
                        :key="key"
                        class="flex flex-col mb-6"
                    >
                        <h2
                            class="py-4 uppercase text-sm"
                        >
                            {{ key }}
                        </h2>
                        <ul>
                            <li
                                v-for="i in values"
                                :key="i.text"
                                class="py-1 text-xs capitalize items-center gap-x-2 flex font-light"
                                @click="select(key, i)"
                            >
                                <input 
                                    type="checkbox"
                                    :checked="isItemSelected(key, i)"
                                    class="appearance-none w-3 h-3 border-2 border-white bg-black text-yellow"
                                />
                                <span>{{ i.text }}</span>
                                <span>({{ i.counter }})</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    class="flex items-center justify-center py-8 gap-x-6 text-sm mt-4"
                >
                    <button
                        class="border-2 border-yellow text-yellow py-2 px-4 font-semibold"
                        @click="clear"
                    >
                        Clear all filters
                    </button>
                    <button
                        class="border-2 border-yellow bg-yellow text-black py-2 px-4 font-semibold"
                        @click="close"
                    >
                        {{ showText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SidebarMobileComponent',
    props: {
        items: {
            type: Object,
            required: true,
            default: () => {}
        },
        filters: {
            type: Array,
            required: true,
            default: () => []
        },
        showText: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {};
    },
    computed: {
        logo() {
            return require('@/assets/img/logo.png');
        }
    },
    methods: {
        select(key, item) {
            this.$emit('selected', { key, item });
        },
        close() {
            this.$emit('close');
        },
        clear() {
            this.$emit('clear');
            this.close();
        },
        isItemSelected(key, item) {
            const filterKey = `${key}|${item.text}`;
            return this.filters.indexOf(filterKey) !== -1;
        }
    }
};
</script>