import HomePage from '@/pages/home.vue';
import WebshopPage from '@/pages/webshop.vue';
import WebshopItemPage from '@/pages/webshop_item.vue';
import ServicesPage from '@/pages/services.vue';
import MembersPage from '@/pages/members.vue';
import TACPage from '@/pages/terms.vue';
import PrivacyPage from '@/pages/privacy.vue';
import NotFoundPage from '@/pages/not_found.vue';

export default [
    { path: '/', component: HomePage, name: 'home' },
    { path: '/services', component: ServicesPage, name: 'services' },
    { path: '/club-members', component: MembersPage, name: 'members' },
    { path: '/shop', component: WebshopPage, name: 'shop' },
    { path: '/shop/:productSlug', component: WebshopItemPage, name: 'shop-item' },
    { path: '/terms-and-conditions', component: TACPage, name: 'tac' },
    { path: '/privacy-policy', component: PrivacyPage, name: 'privacy' },
    { path: "/:notFound", component: NotFoundPage, name: 'not-found' }
];
