<template>
    <div class="flex flex-col gap-y-6">
        <div
            class="leading-relaxed text-xs md:text-sm"
            v-html="product.description"
        ></div>
        <img
            :src="imagePath"
            class="object-cover w-full h-96"
        />
    </div>
</template>
<script>
export default {
    name: 'WebshopItemDescriptionProductTabComponent',
    props: {
        product: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        imagePath() {
            return `${process.env.VUE_APP_MEDIA_PATH}products/${this.product.image}`;
        }
    }
};
</script>