<template>
    <section
        class="w-full bg-darkBlack bg-no-repeat bg-cover"
        :style="heroImage"
    >
        <div class="container mx-auto flex flex-col text-white text-center py-16 gap-y-6 md:gap-y-16 md:py-40">
            <h2 class="font-bold text-xl md:text-5xl">
                Creating <span class="text-yellow">global</span> business opportunities
            </h2>
            <a 
                href="mailto:info@ibizace.com"
                class="text-yellow font-semibold text-sm md:text-2xl"
            >
                info@ibizace.com
            </a>
        </div>
    </section>
</template>
<script>
export default {
    name: 'HomeHeroMapComponent',
    computed: {
        heroImage() {
            const imagePath = require ('@/assets/img/home/hero-map.png');
            return {backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${imagePath})`};
        }
    }
}
</script>