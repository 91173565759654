<template>
    <section class="bg-black text-white p-6">
        <div class="container mx-auto">
            <div
                class="border-t-2 border-b-2 border-white text-white font-extrabold text-center py-4 my-4 md:text-4xl md:py-8 md:my-8"
            >
                We make companies more profitable
            </div>
        </div>
        <div class="container mx-auto py-4 items-center md:flex md:gap-x-8">
            <div class="w-1/2 hidden md:inline-flex items-center">
                <div class="flex gap-x-3">
                    <a 
                        href="https://www.linkedin.com/company/ibiza-center-for-entrepreneurship" 
                        target="_blank"
                    >
                        <img 
                            :src="linkedinLogo" 
                            title="Ibizace Linkedin Page"
                            alt="Ibiza Center For Entrepreneurship Linkedin Page"
                        />
                    </a>
                    <a 
                        href="https://t.me/ibizabusiness"
                        target="_blank"
                    >
                        <img 
                            :src="telegramLogo"
                            title="Ibizace Telegram Group"
                            alt="Ibiza Center For Entrepreneurship Telegram Group"
                        />
                    </a>
                </div>
                <div class="ml-8 flex flex-col gap-y-1">
                    <p>Phone</p>
                    <p>+31 6 2234 7013</p>
                </div>
                <div class="ml-8 flex flex-col gap-y-1">
                    <p>Email</p>
                    <a
                        href="mailto:info@ibizace.com"
                        class="hover:text-yellow"
                    >
                        info@ibizace.com
                    </a>
                </div>
            </div>
            <div class="text-3xs flex md:w-1/2 md:text-2xs md:font-medium">
                <div class="w-1/2">
                    2022 © Ibiza Center for Enterpreneurship
                </div>
                <div class="w-1/2 grid grid-cols-2 gap-x-2 justify-items-end">
                    <router-link 
                        :to="{ name: 'privacy' }"
                        class="hover:text-yellow"
                    >
                        Privacy Policy
                    </router-link>
                    <router-link 
                        :to="{ name: 'tac' }"
                        class="hover:text-yellow"
                    >
                        Terms & Conditions
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'FooterComponent',
    computed: {
        linkedinLogo() {
            return require ('@/assets/img/linkedin.png');
        },
        telegramLogo() {
            return require ('@/assets/img/telegram.png');
        }
    }
};
</script>