<template>
    <input
        :type="type"
        :name="name"
        :placeholder="placeholder"
        :disabled="loading"
        required
        class="w-full bg-yellow border-b-2 border-black outline-none text-gray-400 placeholder:text-gray-400 rounded-none focus:border-black"
        @keyup="onKeyUp"
        @focus="onFocus"
    />
</template>
<script>
export default {
    name: 'SubscriptionInputComponent',
    props: {
        type: {
            type: String,
            required: true,
            default: ''
        },
        name: {
            type: String,
            required: true,
            default: ''
        },
        placeholder: {
            type: String,
            required: true,
            default: ''
        },
        loading: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    methods: {
        onKeyUp(event) {
            this.$emit('change', event);
        },
        onFocus() {
            this.$emit('focus');
        }
    }
}
</script>