<template>
    <div class="flex flex-col h-auto bg-darkBlack">
        <div class="w-full h-max">
            <div
                class="w-full text-white text-sm items-center grid grid-cols-2 py-4 md:hidden"
            >
                <div 
                    class="px-4"
                    @click="mobile.show = true"
                >
                    <font-awesome-icon 
                        :icon="['fas', 'sliders']"
                        class="text-sm text-yellow mr-2"
                    />
                    Filters ({{ filters.length }})
                </div>
                <div
                    class="px-6 text-right"
                >
                    <a 
                        href="https://chat.whatsapp.com/IcT3bz8z2ee72pkOiSzdec"
                        target="_blank"
                        class="h-48 py-2 px-3 font-bold bg-yellow text-black text-xs border-2 border-yellow hover:bg-black hover:text-yellow"
                    >
                        Join our club
                    </a>
                </div>
            </div>
            <div
                class="w-full flex"
            >
                <FiltersSidebarComponent
                    :items="sidebarItems"
                    class="hidden w-1/6 md:flex"
                    @selected="onSidebarFilter"
                />
                <div class="w-full md:w-5/6 flex flex-col">
                    <div class="flex flex-col w-full gap-y-2 md:w-3/4 text-white py-4">
                        <FiltersHeaderComponent 
                            :items="filters"
                            @onFilterChange="deleteFilter"
                            @onFilterClear="filters = []"
                        />
                    </div>
                    <MapComponent
                        :markers="filteredMarkers"
                        @selected="(m) => memberSelected = m"
                    />
                </div>
            </div>
        </div>
        <FiltersMobileComponent
            v-if="mobile.show"
            :items="sidebarItems"
            :filters="filters"
            show-text="Show members"
            class="flex md:hidden"
            @selected="onSidebarFilter"
            @close="mobile.show = false"
            @clear="filters = []"
        />
        <MapSidebarComponent
            :selected="memberSelected"
            @close="onCloseSideBar"
        />
        <SubscriptionComponent />
    </div>
</template>
<script>
import SubscriptionComponent from "@/components/subscription";
import FiltersMobileComponent from '@/components/shared/filters_mobile.vue';
import FiltersSidebarComponent from '@/components/shared/filters_sidebar.vue';
import FiltersHeaderComponent from '@/components/shared/filters_header.vue';
import MapComponent from '@/components/map/map.vue';
import MapSidebarComponent from '@/components/map/sidebar.vue';

export default {
    name: 'MembersPage',
    components: {
        FiltersMobileComponent,
        FiltersSidebarComponent,
        FiltersHeaderComponent,
        MapComponent,
        MapSidebarComponent,
        SubscriptionComponent,
    },
    data() {
        return {
            markers: require('@/assets/members.json'),
            memberSelected: null,
            filters: [],
            mobile: {
                show: false
            }
        };
    },
    computed: {
        filteredMarkers() {
            if (this.filters.length === 0) return this.markers;
            return this.markers.filter((m) => {
                const mTypes =  m.info.type.split(',').map((i) => `types|${i}`);
                
                const industries = m.info.industries
                    .reduce((acc, i) => { 
                        const filterKey = `industries|${i}`;
                        acc.push (filterKey); 
                        return acc;
                    }, []);

                const business = m.info.business
                    .reduce((acc, i) => { 
                        const filterKey = `business|${i}`;
                        acc.push (filterKey); 
                        return acc;
                    }, []);
                
                return (
                    mTypes.filter(x => this.filters.includes(x)).length > 0
                    || industries.filter(x => this.filters.includes(x)).length > 0
                    || business.filter(x => this.filters.includes(x)).length > 0
                );

            });
        },
        sidebarItems() {
            let result = {
                types: {},
                industries: {},
                business: {}
            };
            this.markers.forEach((m) => {
                const data = {
                    types: m.info.type.split(',').map((i) => i.trim()),
                    industries: m.info.industries,
                    business: m.info.business
                };
                Object.keys(data).forEach((k) => {
                    data[k].forEach((i) => {
                        if (!Object.keys(result[k]).includes(i.toLowerCase())) {
                            result[k][i.toLowerCase()] = {
                                text: i,
                                counter: 0
                            };
                        }
                        result[k][i.toLowerCase()].counter += 1;
                    });
                });
            });
            return {
                types: Object.values(result.types),
                industries: Object.values(result.industries),
                business: Object.values(result.industries)
            };
        }
    },
    methods: {
        onSidebarFilter(evt) {
            const filterKey = `${evt.key}|${evt.item.text}`;
            if (this.filters.includes(filterKey)) {
                this.deleteFilter(filterKey);
            } else {
                this.filters.push(filterKey);
            }
        },
        deleteFilter(filterKey) {
            this.filters = this.filters.filter(i => i !== filterKey);
        },
        onFilterClear() {
            this.memberSelected = null;
            this.filtersSelected = [];
        },
        onCloseSideBar() {
            this.memberSelected = null;
        }
    },
    metaInfo() {
        const baseUrl = process.env.VUE_APP_BASE_URL;
        return {
            title: 'Club members - We make companies more profitable.',
            meta: [
                {
                    name: 'description',
                    content: 'Contact with our club members of join to our club if you want to growth your business'
                },
                {
                    property: 'og:title',
                    content: 'Club members - We make companies more profitable.',
                },
                {
                    property: 'og:url',
                    content: baseUrl
                },
                {
                    property: 'og:image',
                    content: `${baseUrl}img/home/home-hero.jpg`
                }    
            ],
            link: [
                {
                    rel: 'canonical',
                    href: `${baseUrl}club-members`
                }
            ]
        };
    }
};
</script>