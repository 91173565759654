<template>
    <div 
        class="flex flex-col gap-y-6 md:flex-row gap-x-6"
    >
        <div
            class="md:w-1/4"
        >
            <img
                :src="imagePath(product.company.image)"
                class="object-contain object-top w-full h-full"
            />
        </div>
        <div class="md:w-3/4 flex flex-col gap-y-6"> 
            <div 
                v-if="product.company.url"
                class="w-full flex flex-col gap-y-2"
            >
                <span
                    class="text-xs md:text-sm"
                >
                    Visit website
                </span>
                <a 
                    :href="product.company.url"
                    target="_blank"
                    class="font-semibold text-sm md:text-base"
                >
                    {{ product.company.url }}
                </a>
            </div>
            <div 
                v-if="product.company.location"
                class="w-full flex flex-col gap-y-2"
            >
                <span
                    class="font-semibold text-sm md:text-base"
                >
                    Location
                </span>
                <span
                    class="font-semibold"
                >
                    {{ product.company.location }}
                </span>
            </div>
            <p
                class="text-sm md:text-base"
                v-html="product.company.description"
            ></p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'WebshopItemDescriptionCompanyTabComponent',
    props: {
        product: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    methods: {
        imagePath(path) {
            return path ? `${process.env.VUE_APP_MEDIA_PATH}products/${path}`: require(`@/assets/img/not-available.jpg`);
        }
    }
};
</script>