<template>
    <div
        id="app"
        class="w-full h-screen flex flex-col m-0 p-0 font-helvetica"
    >
        <HeaderComponent />
        <router-view />
        <FooterComponent />
        <CookieBannerComponent />
    </div>
</template>

<script>
import HeaderComponent from '@/components/header.vue';
import FooterComponent from "@/components/footer.vue";
import CookieBannerComponent from "@/components/cookies.vue";

export default {
    name: "App",
    components: {
        HeaderComponent,
        FooterComponent,
        CookieBannerComponent
    },
    metaInfo: {
        title: 'We make companies more profitable',
        meta: [
            { 
                property: 'og:site_name',
                content: 'Ibizace'
            },
            { 
                property: 'og:type',
                content: 'website'
            },
            {
                name: 'robots',
                content: 'index,follow'
            } 
        ]
    }
};
</script>
