<template>
    <section 
        class="w-full bg-center bg-no-repeat bg-cover py-4 md:py-16" 
        :style="backgroundImage"
    >
        <div 
            class="container mx-auto flex items-center justify-center"
        >
            <div class="container mx-auto text-white py-2 md:py-8">
                <slot></slot>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'HeroComponent',
    props: {
        backgroundImage: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
};
</script>