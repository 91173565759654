<template>
    <gmap-map
        :options="mapOptions"
        :center="center"
        style="width:100%;  height: 100vh; padding: 0; margin: 0;"
    >
        <gmap-marker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            :icon="markerIconUrl"
            :clickable="true"
            :draggable="false"
            @click="selectMarker(m)"
        >
            <gmap-info-window
                :closeclick="false"
                :opened="selectedMarker && selectedMarker.id === m.id"
            >
                <FontAwesomeIcon
                    icon="fa-solid fa-xmark"
                    class="absolute right-3 top-3 cursor-pointer font-extralight text-md"
                    style="color: #AAAAAA"
                    @click="selectedMarker = null"
                />
                <div class="flex flex-col h-full m-4">
                    <div class="grid grid-flow-col auto-cols-max gap-x-4">
                        <div class="flex flex-col gap-y-5 items-center">
                            <img
                                :src="logo(m)"
                                :alt="`${m.info.name} logo`"
                                class="w-28"
                            />
                            <a
                                :href="createLink(m)"
                                class="border border-yellow text-yellow px-4 py-3"
                            >
                                Get introduced
                            </a>
                        </div>
                        <div class="flex flex-col gap-y-4">
                            <div>
                                <div class="font-semibold text-xl uppercase">
                                    {{ m.info.name }}
                                </div>
                                <div class="mt-3">
                                    {{ m.info.type }}
                                </div>
                                <div>
                                    {{ m.info.industries.join(' | ') }}
                                </div>
                                <div class="mb-3">
                                    {{ m.info.business.join(' | ') }}
                                </div>
                                <div>{{ m.info.location }}</div>
                            </div>
                            <div class="flex justify-between">
                                <a
                                    href="#"
                                    class="text-white py-3"
                                    @click="readMore(m)"
                                >
                                    Read more
                                    <FontAwesomeIcon
                                        icon="fa-solid fa-chevron-down"
                                        class="ml-2"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </gmap-info-window>
        </gmap-marker>
    </gmap-map>
</template>
<script>
export default {
    name: 'MapComponent',

    props: {
        markers: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            center: { lat: 46.3086106, lng: -3.8943332 },
            mapOptions: {
                mapId: process.env.VUE_APP_GOOGLE_MAPS_MAP_ID,
                zoom: 3,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
            },
            selectedMarker: null
        };
    },
    computed: {
        markerIconUrl() {
            return require(`@/assets/img/dot.png`);
        }
    },
    methods: {
        logo(m) {
            return m ? `${process.env.VUE_APP_MEDIA_PATH}members/${m.info.image}`: require(`@/assets/img/no-user.png`);
        },
        selectMarker(m) {
            this.$emit('selected', null);
            this.selectedMarker = m;
        },
        readMore(m) {
            this.selectedMarker = null;
            this.$emit('selected', m);
        },
        createLink(member) {
            return `mailto:info@ibizace.com?subject=I would like to talk with ${member.info.name}`;
        }
    }
}
</script>
<style>
.gm-style .gm-style-iw-tc {
	display: none;
}

.gm-style-iw-d {
    max-width: fit-content !important;
    max-height: fit-content !important;
    overflow: hidden !important;
}

.gm-style-iw, .gm-style-iw-c {
	border-radius: 0 !important;
	background-color: #000 !important;
	color: #FFF !important;
    padding: 0 !important;
    max-width: fit-content !important;
    max-height: fit-content !important;
}
</style>