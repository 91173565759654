<template>
    <div
        class="py-4 flex flex-col text-white"
    >
        <div
            v-for="(values, key) in items"
            :key="key"
            class="flex flex-col mb-4"
        >
            <h2
                class="py-4 text-lg font-semibold uppercase"
            >
                {{ key }}
            </h2>
            <ul>
                <li
                    v-for="i in values"
                    :key="i.text"
                    class="py-1 capitalize cursor-pointer"
                    @click="select(key, i)"
                >
                    {{ i.text }} ({{ i.counter }})
                </li>
            </ul>
        </div>
        <div
            class="flex flex-col py-4"
        >
            <a
                v-for="l in links"
                :key="l.text"
                :href="l.link"
                target="_blank"
                class="flex items-center py-2 text-lg font-semibold"
            >
                <font-awesome-icon 
                    :icon="['fas', l.icon]" 
                    class="text-sm font-semibold border-2 rounded-full p-2"
                />
                <span
                    class="ml-4"
                >
                    {{ l.text }}
                </span>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FiltersSidebarComponent',
    props: {
        items: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            links: [
                {
                    link: 'https://chat.whatsapp.com/IcT3bz8z2ee72pkOiSzdec',
                    text: 'Join our club',
                    icon: 'phone'
                }, 
                {
                    link: 'mailto:info@ibizace.com',
                    text: 'Get in touch',
                    icon: 'envelope'
                }
            ]
        };
    },
    methods: {
        select(key, item) {
            this.$emit('selected', { key, item });
        }
    }
};
</script>
