<template>
    <section
        class="w-full bg-darkBlack"
    >
        <div class="container mx-auto flex flex-col text-white px-6 pb-8 gap-y-10">
            <JoinBlockComponent
                v-for="i in items"
                :key="i.id"
                :position="i.position"
                :title-prev="i.titlePrev"
                :title-post="i.titlePost"
                :title-high="i.titleHigh"
                :content="i.content"
                :link="i.link"
                :link-text="i.linkText"
                :image-path="i.imagePath"
            />
        </div>
    </section>
</template>
<script>
import JoinBlockComponent from '@/components/home/join_block.vue';
export default {
    name: 'HomeJoinComponent',
    components: {
        JoinBlockComponent
    },
    data() {
        return {
            items: [
                {
                    id: 'join-club',
                    position: 1,
                    titlePrev: 'Join our',
                    titlePost: '',
                    titleHigh: 'club',
                    content: 'Use the knowledge, experience and network of our club members.',
                    link: 'https://chat.whatsapp.com/IcT3bz8z2ee72pkOiSzdec',
                    linkText: 'Join now',
                    imagePath: 'img/home/join-our-club.png'
                },
                {
                    id: 'join-f6s',
                    position: 2,
                    titlePrev: 'Join our',
                    titlePost: 'via F6S',
                    titleHigh: 'startup program',
                    content: 'With our startup program we mentor & coach startups and help with expanding your business. We also invest up to €25K per startup team if you meet our requirements.',
                    link: 'https://www.f6s.com/ibiza-center-for-entrepreneurship-2023',
                    linkText: 'Apply now',
                    imagePath: 'img/home/join-our-club-1.png'
                }
            ]
        }
    }
};
</script>