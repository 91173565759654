<template>
    <div class="bg-darkBlack py-4 text-white flex items-center w-full overflow-x-hidden">
        <div 
            v-for="i in items"
            :key="i.name"
            class="flex text-xs font-semibold items-center mr-4 md:text-sm md:mr-6"
            :class="{'font-semibold': i?.link}"
        >
            <font-awesome-icon 
                :icon="['fas', 'angle-left']" 
                class="mr-2 md:mr-4"
            />
            <div 
                v-if="i?.link"
            >
                <router-link 
                    :to="{ name: i.link }"
                    class="p-0"
                >
                    {{ i.name }}
                </router-link>
            </div>
            <span 
                v-else
                class="font-extralight break-normal"
            >
                {{ i.name }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: "BreadcrumbComponent",
    props: {
        items: {
            type: Array,
            required: true,
            default: () => []
        }
    }
};
</script>