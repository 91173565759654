<template>
    <div
        class="w-full py-6 flex gap-x-10 items-center justify-end px-8 md:px-4"
    >
        <button
            v-if="currentPage !== 1"
            class="border-2 border-yellow items-center w-8 h-8 md:w-14 md:h-16"
            @click="previous"
        >
            <font-awesome-icon 
                :icon="['fas', 'angle-left']" 
                class="font-semibold text-yellow text-sm md:text-2xl"
            />
        </button>
        <div
            class="text-2xl"
        >
            {{ currentPage }} / {{ totalPages }}
        </div>
        <button
            v-if="currentPage !== totalPages"
            class="border-2 border-yellow items-center w-8 h-8 md:w-14 md:h-16"
            @click="next"
        >
            <font-awesome-icon 
                :icon="['fas', 'angle-right']" 
                class="font-semibold text-yellow text-sm md:text-2xl"
            />
        </button>
    </div>
</template>
<script>
export default {
    name: 'WebshopPaginationComponent',
    props: {
        currentPage: {
            type: Number,
            required: true,
            default: 1
        },
        totalPages: {
            type: Number,
            required: true,
            default: 1
        }
    },
    methods: {
        previous() {
            this.$emit('previous');
        },
        next() {
            this.$emit('next');
        }
    }
};
</script>