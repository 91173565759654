<template>
    <div class="flex flex-col h-auto bg-darkBlack">
        <HeroComponent
            :background-image="heroImage"
        >
            <slot>
                <h1
                    class="w-full text-xl font-extrabold text-center md:text-3xl"
                >
                    Welcome to <span class="text-yellow">our webshop</span>
                </h1>
                <p class="w-full my-8 text-center text-xs font-semibold md:leading-6 md:text-sm">
                    Buy directly products and services from our 
                    <span class="text-yellow">club members</span>
                    and grow together with them
                </p>
            </slot>
        </HeroComponent>
        <div class="flex w-full md:hidden">
            <FiltersMobileComponent
                v-if="mobile.show"
                :items="sidebarItems"
                :filters="filters"
                show-text="Show services"
                class="flex md:hidden"
                @selected="onSidebarFilter"
                @close="mobile.show = false"
                @clear="filters = []"
            />
        </div>
        <div class="container mx-auto md:py-2">
            <div
                class="w-full px-4 flex md:py-2 md:px-0"
            >
                <BreadcrumbComponent 
                    :items="breadcrumb"
                    class="w-1/2"
                />
                <WebshopShortComponent
                    :selected="sortInfo.selected"
                    :items="sortInfo.items"
                    class="w-1/2 hidden md:flex"
                    @change="onSortChange"
                />
            </div>
            <div
                class="w-full text-center text-white text-sm items-center py-4 md:hidden"
                @click="mobile.show = true"
            >
                <font-awesome-icon 
                    :icon="['fas', 'sliders']"
                    class="text-sm text-yellow mr-2"
                />
                Filters ({{ filters.length }})
            </div>
            <div
                class="w-full flex"
            >
                <FiltersSidebarComponent
                    :items="sidebarItems"
                    class="hidden w-1/4 md:flex"
                    @selected="onSidebarFilter"
                />
                <div class="w-full md:w-3/4 flex flex-col">
                    <div class="flex flex-col w-full gap-y-2 md:w-3/4 text-white">
                        <FiltersHeaderComponent 
                            :items="filters"
                            @onFilterChange="deleteFilter"
                            @onFilterClear="filters = []"
                        />
                    </div>
                    <WebshopGridComponent
                        :items="paginatedItems"
                    />
                    <WebshopPaginationComponent
                        v-if="filteredProducts.length > pagination.maxItems"
                        :current-page="pagination.currentPage"
                        :total-pages="maxPages"
                        @previous="changePage(-1)"
                        @next="changePage(1)"
                    />
                </div>
            </div>
            <WebshopJoinComponent />
            <TestimonialsComponent />
        </div>
        <SubscriptionComponent />
    </div>
</template>
<script>
import SubscriptionComponent from '@/components/subscription';
import HeroComponent from '@/components/shared/hero.vue';
import BreadcrumbComponent from '@/components/shared/breadcrumb.vue';
import FiltersMobileComponent from '@/components/shared/filters_mobile.vue';
import FiltersSidebarComponent from '@/components/shared/filters_sidebar.vue';
import FiltersHeaderComponent from '@/components/shared/filters_header.vue';
import TestimonialsComponent from '@/components/shared/testimonials.vue';

import WebshopShortComponent from '@/components/webshop/short.vue';
import WebshopGridComponent from '@/components/webshop/grid.vue';
import WebshopPaginationComponent from '@/components/webshop/pagination.vue';
import WebshopJoinComponent from '@/components/webshop/join.vue';

export default {
    name: 'WebshopPage',
    components: {
        BreadcrumbComponent,
        HeroComponent,
        FiltersMobileComponent,
        FiltersSidebarComponent,
        FiltersHeaderComponent,
        WebshopShortComponent,
        WebshopGridComponent,
        WebshopPaginationComponent,
        WebshopJoinComponent,
        TestimonialsComponent,
        SubscriptionComponent
    },
    data() {
        return {
            products: require('@/assets/products.json'),
            breadcrumb: [
                {
                    name: 'Home',
                    link: 'home'
                },
                {
                    name: 'Shop'
                }
            ],
            sortInfo: {
                selected: 0,
                items: [
                    {
                        key: '-published',
                        text: 'Most recent'
                    },
                    {
                        key: 'published',
                        text: 'Less recent'
                    },
                    {
                        key: 'title',
                        text: 'Name (A->Z)'
                    },
                    {
                        key: '-title',
                        text: 'Name (Z->A)'
                    }
                ]
            },
            filters: [],
            pagination: {
                maxItems: 6,
                currentPage: 1
            },
            mobile: {
                show: false
            }
        };
    },
    computed: {
        heroImage() {
            const imagePath = require ('@/assets/img/webshop/webshop-hero.png');
            return {backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4)), url(${imagePath})`};
        },
        sidebarItems() {
            let result = {
                industries: {},
                business: {}
            };
            this.products.forEach((p) => {
                p.industries.forEach((i) => {
                    if (!Object.keys(result.industries).includes(i.toLowerCase())) {
                        result.industries[i.toLowerCase()] = {
                            text: i,
                            counter: 0
                        };
                    }
                    result.industries[i.toLowerCase()].counter += 1;
                });
                p.business.forEach((b) => {
                    if (!Object.keys(result.business).includes(b.toLowerCase())) {
                        result.business[b.toLowerCase()] = {
                            text: b,
                            counter: 0
                        };
                    }
                    result.business[b.toLowerCase()].counter += 1;
                });
            });

            return {
                industries: Object.values(result.industries),
                business: Object.values(result.business)
            }
        },
        filteredProducts() {
            return this.products
                .filter((p) => {
                    const industries = p.industries
                        .reduce((acc, i) => { 
                            const filterKey = `industries|${i.toLowerCase()}`;
                            acc.push (filterKey); 
                            return acc;
                        }, []);

                    const business = p.business
                        .reduce((acc, i) => { 
                            const filterKey = `business|${i.toLowerCase()}`;
                            acc.push (filterKey); 
                            return acc;
                        }, []);

                    return (
                        this.filters.length === 0 
                        || industries.filter(x => this.filters.includes(x)).length > 0
                        || business.filter(x => this.filters.includes(x)).length > 0
                    );
                })
                .sort((a, b) => {
                    let reversed = false;
                    let sortKey = this.sortInfo.items[this.sortInfo.selected].key;
                    if (sortKey[0] === '-') {
                        reversed = true;
                        sortKey = sortKey.substring(1);
                    }
                    return reversed 
                        ? b[sortKey].localeCompare(a[sortKey])
                        : a[sortKey].localeCompare(b[sortKey]) ;
                });
        },
        paginatedItems() {
            const startItem = (this.pagination.currentPage - 1) * this.pagination.maxItems;
            return this.filteredProducts.slice(
                startItem,
                startItem + this.pagination.maxItems
            );
        },
        maxPages() {
            const totalVisibleProducts = this.filteredProducts.length;
            return Math.ceil(totalVisibleProducts / this.pagination.maxItems);
        }
    },
    methods: {
        changePage(variation) {
            this.pagination.currentPage = this.pagination.currentPage + variation;
            const container = document.getElementById('app')
            if (container) container.scrollIntoView({ behavior: 'smooth' });
        },
        onSortChange(index) {
            this.sortInfo.selected = index;
        },
        onSidebarFilter(evt) {
            const filterKey = `${evt.key}|${evt.item.text}`;
            if (this.filters.includes(filterKey)) {
                this.deleteFilter(filterKey);
            } else {
                this.filters.push(filterKey);
            }
            this.pagination.currentPage = 1;
        },
        deleteFilter(filterKey) {
            this.filters = this.filters.filter(i => i !== filterKey);
        }
    },
    metaInfo() {
        const baseUrl = process.env.VUE_APP_BASE_URL;
        return {
            title: 'Shop - We make companies more profitable.',
            meta: [
                {
                    name: 'description',
                    content: 'Buy directly products and services from our club members and grow together with them'
                },
                {
                    property: 'og:title',
                    content: 'Shop - We make companies more profitable.',
                },
                {
                    property: 'og:url',
                    content: baseUrl
                },
                {
                    property: 'og:image',
                    content: `${baseUrl}img/webshop/webshop-hero.png`
                }    
            ],
            link: [
                {
                    rel: 'canonical',
                    href: `${baseUrl}shop`
                }
            ]
        };
    }
};
</script>