<template>
    <transition name="slide">
        <div 
            v-if="selected"
            class="absolute inset-y-24 z-40 p-4 w-full bg-black text-white items-top flex flex-col md:w-1/2 md:inset-y-29 md:bottom-0 md:py-12"
            :class="{'hidden': !selected}"
        >
            <FontAwesomeIcon
                icon="fa-solid fa-xmark"
                class="absolute right-6 cursor-pointer font-extralight text-2xl"
                style="color: #AAAAAA"
                @click="close"
            />
            <div class="grid grid-cols-2 grid-flow-col auto-cols-max md:gap-x-5">
                <div class="flex flex-col gap-y-5 items-center">
                    <img
                        :src="logo(selected)"
                        :alt="`${selected.info.name} logo`"
                        class="w-logo"
                    />
                    <a
                        :href="introLink"
                        class="border border-yellow text-yellow text-sm px-4 py-2 md:text-base md:px-5 md:py-3"
                    >
                        Get introduced
                    </a>
                </div>
                <div class="flex flex-col gap-y-5">
                    <div>
                        <div class="font-semibold uppercase text-sm md:text-xl">
                            {{ selected.info.name }}
                        </div>
                        <div class="text-xs mt-2 md:text-base md:mt-4 ">
                            {{ selected.info.type }}
                        </div>
                        <div class="text-xs md:text-base">
                            {{ formattedIndustries }}
                        </div>
                        <div class="text-xs mb-2 md:text-base md:mb-4">
                            {{ formattedBusiness }}
                        </div>
                        <div class="text-xs md:text-base">
                            {{ selected.info.location }}
                        </div>
                    </div>
                    <div class="flex justify-between">
                        <a
                            href="#"
                            class="text-white py-2 text-sm md:text-base"
                            @click="showMore = !showMore"
                        >
                            Read {{ showMore ? 'less' : 'more' }}
                            <FontAwesomeIcon
                                v-if="showMore"
                                icon="fa-solid fa-chevron-up"
                                class="ml-2"
                            />
                            <FontAwesomeIcon
                                v-else
                                icon="fa-solid fa-chevron-down"
                                class="ml-2"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div 
                v-if="showMore"
                class="max-h-fit overflow-y-auto px-2 md:px-14 md:mt-4"
            >
                <div class="overflow-y-auto">
                    <div class="py-4 mt-2 font-medium md:text-xl">
                        In short
                    </div>
                    <p class="font-light text-sm md:text-base">
                        {{ selected.info.in_short }}
                    </p>
                    <div class="py-4 mt-2 font-medium md:text-xl">
                        How can I help
                    </div>
                    <p class="font-light text-sm md:text-base">
                        {{ selected.info.help }}
                    </p>
                    <div class="py-4 mt-2 font-medium md:text-xl">
                        What I'm looking for
                    </div>
                    <p class="font-light text-sm md:text-base">
                        {{ selected.info.looking }}
                    </p>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'MapSidebarComponent',
    props: {
        selected: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            showMore: true
        };
    },
    computed: {
        formattedIndustries() {
            return this.selected
                ? this.selected.info.industries.join(' | ')
                : '';
        },
        formattedBusiness() {
            return this.selected
                ? this.selected.info.business.join(' | ')
                : '';
        },
        introLink() {
            return `mailto:info@ibizace.com?subject=I would like to talk with ${this.selected.info.name}`;
        }
    },
    methods: {
        logo(m) {
            return m ? `${process.env.VUE_APP_MEDIA_PATH}members/${m.info.image}`: require(`@/assets/img/no-user.png`);
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>
<style scoped>
.slide-enter-active, .slide-leave-active {
  transition: opacity 0.5s;
}
.slide-enter, .slide-leave-to{
    opacity: 0;
}
</style>