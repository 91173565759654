<template>
    <div class="flex flex-col gap-y-10 md:gap-y-20">
        <div
            :id="containerId"
            class="w-full gap-x-5 flex overflow-x-hidden"
        >
            <div 
                v-for="i in items"
                :key="i.order"
                class="text-white flex-none"
                :class="{'hover:bg-yellow hover:text-black': hoverHighlight}"
            >
                <slot 
                    name="card"
                    v-bind="i"
                ></slot>
            </div>
        </div>
        <div
            v-if="showControls" 
            class="text-yellow flex text-base items-center justify-center gap-x-6 md:gap-x-10 md:text-2xl md:justify-end"
        >
            <button
                class="border-2 border-yellow items-center w-8 h-10 md:w-14 md:h-16"
                @click="left"
            >
                <font-awesome-icon 
                    :icon="['fas', 'angle-left']" 
                    class="font-semibold"
                />
            </button>
            <div
                v-if="showNumbers"
            >
                {{ currentItem }} / 
                {{ items.length }}
            </div>
            <button
                class="border-2 border-yellow items-center w-8 h-10 md:w-14 md:h-16"
                @click="right"
            >
                <font-awesome-icon 
                    :icon="['fas', 'angle-right']" 
                    class="font-semibold"
                />
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SliderComponent',
    props: {
        containerId: {
            type: String,
            required: true,
            default: () => ''
        },
        items: {
            type: Array,
            required: true,
            default: () => []
        },
        showControls: {
            type: Boolean,
            required: true,
            default: true
        },
        showNumbers: {
            type: Boolean,
            required: true,
            default: false
        },
        hoverHighlight: {
            type: Boolean,
            required: true,
            default: true
        },
        autoPlay: {
            type: Boolean,
            required: true,
            default: true
        },
        fullMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        currentItem() {
            return 1;
        },
        _itemSize() {
            const container = document.querySelector(`#${this.containerId}`);
            return Math.ceil(container.scrollWidth / this.items.length);
        },
    },
    created() {
        if (this.autoPlay) {
            window.setInterval(() => {
                this.right()
            }, 3000);
        }
    },
    methods: {
        left() {
            this._scroll(-1 * this._variation());

        },
        right() {
            this._scroll(this._variation());
        },
        _scroll(newPosition) {
            const container = document.querySelector(`#${this.containerId}`);
            if (container) container.scrollBy(newPosition, 0);
        },
        _variation() {
            return this.fullMode ? this._itemSize : this._itemSize  / 2;
        }
    }
}
</script>