<template>
    <header class="w-full bg-darkBlack z-50">
        <div class="container mx-auto flex pt-4 pb-2 px-6">
            <div class="w-1/2 flex items-center md:w-1/6">
                <div
                    class="md:hidden text-white mr-3"
                    @click="collapsed = !collapsed"
                >
                    <font-awesome-icon
                        :icon="['fas', 'bars']"
                        class="text-2xl"
                    />
                </div>
                <a class="cursor-pointer" @click="navigate('home')">
                    <img
                        :src="logo"
                        alt="We make companies more profitable"
                        class="object-cover h-16 md:h-auto"
                    />
                </a>
            </div>
            <div class="hidden md:flex flex-grow items-center gap-x-4">
                <div
                    v-for="i in menuItems"
                    :key="i.key"
                    :class="{ ' border-yellow text-yellow': isLinkActive(i) }"
                    class="text-white border-b-2 border-transparent hover:border-yellow hover:text-yellow"
                >
                    <router-link
                        v-if="!i.target"
                        :to="{ name: i.link }"
                        class="block font-bold text-sm py-3 px-5"
                    >
                        {{ i.name }}
                    </router-link>
                    <a
                        v-else
                        :href="i.link"
                        :target="i.target"
                        class="block font-bold text-sm py-3 px-5"
                    >
                        {{ i.name }}
                    </a>
                </div>
            </div>
            <div class="w-1/2 flex items-center justify-end md:w-1/6">
                <a
                    href="https://www.f6s.com/ibiza-center-for-entrepreneurship-2023"
                    class="border-2 border-yellow text-yellow hover:bg-yellow hover:text-black font-bold text-sm px-3 py-2 md:text-lg md:p-4"
                    target="_blank"
                >
                    Pitch to us
                </a>
            </div>
        </div>
        <div
            v-if="!collapsed"
            class="w-full absolute bg-darkBlack transition-all duration-300 ease-in-out pb-4"
        >
            <ul>
                <li
                    v-for="i in menuItems"
                    :key="i.key"
                    :class="{ ' border-yellow text-yellow': isLinkActive(i) }"
                    class="text-white border-b-2 border-transparent hover:border-yellow hover:text-yellow"
                >
                    <a
                        v-if="!i.target"
                        class="block font-bold text-sm py-3 px-5"
                        @click="navigate(i.link)"
                    >
                        {{ i.name }}
                    </a>
                    <a
                        v-else
                        :href="i.link"
                        :target="i.target"
                        class="block font-bold text-sm py-3 px-5"
                    >
                        {{ i.name }}
                    </a>
                </li>
            </ul>
        </div>
    </header>
</template>
<script>
export default {
    name: "HeaderComponent",
    data() {
        return {
            collapsed: true,
            menuItems: [
                // {
                //     key: 1,
                //     name: 'Services',
                //     active: false,
                //     link: 'services'
                // },
                {
                    key: 2,
                    name: "Club Members",
                    active: false,
                    link: "members",
                },
                {
                    key: 3,
                    name: "Shop",
                    active: false,
                    link: "shop",
                },
                {
                    key: 4,
                    name: "Insights",
                    active: false,
                    link: `https://www.ibizace.com/insights`,
                    target: "_blank",
                },
            ],
        };
    },
    computed: {
        logo() {
            return require("@/assets/img/logo.png");
        },
    },
    methods: {
        isLinkActive(item) {
            return this.$route.name === item.link;
        },
        navigate(dest) {
            this.collapsed = true;
            if (this.$route.name !== dest) {
                this.$router.push({ name: dest });
            }
        },
    },
};
</script>
