<template>
    <div class="container mx-auto p-10 text-gray-600 h-screen">
        <h1 class="text-2xl font-bold text-black mt-8">
            404 Not found. 
        </h1>
        <p class="mt-8">
            The page you are looking for does not exist.
        </p>
    </div>
</template>
<script>
export default {
    name: 'NotFoundPage',
    metaInfo() {
        const baseUrl = process.env.VUE_APP_BASE_URL;
        return {
            title: 'We make companies more profitable - Ibiza Center for Entrepreneurship (ICE) is the central place for anything related to entrepreneurship on the island, internationally and online.',
            titleTemplate: null,
            meta: [
                {
                    name: 'description',
                    content: 'Ibiza Center for Entrepreneurship (ICE) is the central place for anything related to entrepreneurship on the island, internationally and online.'
                },
                {
                    property: 'og:title',
                    content: 'We make companies more profitable - Ibiza Center for Entrepreneurship (ICE) is the central place for anything related to entrepreneurship on the island, internationally and online.'
                },
                {
                    property: 'og:url',
                    content: baseUrl
                },
                {
                    property: 'og:image',
                    content: `${baseUrl}img/home/home-hero.jpg`
                }    
            ],
            link: [
                {
                    rel: 'canonical',
                    href: baseUrl
                }
            ]
        };
    }
};
</script>