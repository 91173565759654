<template>
    <div class="w-full flex flex-col">
        <div
            class="grid grid-col-2"
        >
            <WebshopItemDescriptionNavigatorComponent
                :active-tab="descriptionTab"
                @change="(tab) => descriptionTab = tab"
            />
        </div>
        <div class="py-8">
            <WebshopItemDescriptionProductTabComponent
                v-show="descriptionTab === 0"
                :product="product"
            />
            <WebshopItemDescriptionCompanyTabComponent
                v-show="descriptionTab === 1"
                :product="product"
            />
        </div>
    </div>
</template>
<script>
import WebshopItemDescriptionNavigatorComponent from '@/components/webshop_item/description/navigator.vue';
import WebshopItemDescriptionProductTabComponent from '@/components/webshop_item/description/product_tab.vue';
import WebshopItemDescriptionCompanyTabComponent from '@/components/webshop_item/description/company_tab.vue';
export default {
    name: 'WebshopItemDescriptionComponent',
    components: {
        WebshopItemDescriptionNavigatorComponent,
        WebshopItemDescriptionProductTabComponent,
        WebshopItemDescriptionCompanyTabComponent
    },
    props: {
        product: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            descriptionTab: 0
        };
    }
};
</script>