<template>
    <div class="w-full hidden md:flex">
        <div 
            class="container mx-auto w-full flex pl-3"
        >
            <div
                v-if="items.length >= 1"
                class="uppercase font-medium cursor-pointer py-1 min-w-max"
                @click="clearFilters"
            >
                Clear all
            </div>
            <div class="flex flex-wrap gap-x-5 ml-10">
                <div
                    v-for="f in items"
                    :key="f"
                    class="lower cursor-pointer items-center py-1"
                    @click="selectFilter(f.split('|')[0], f.split('|')[1])"
                >
                    {{ f.split('|')[1] }} 
                    <FontAwesomeIcon
                        icon="fa-solid fa-xmark"
                        class="text-sm"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FiltersHeaderComponent',
    props: {
        items: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        selectFilter(parent, key) {
            const filterKey = `${parent.toLowerCase()}|${key.toLowerCase()}`;
            this.$emit('onFilterChange', filterKey);
        },
        clearFilters() {
            this.$emit('onFilterClear');
        }
    }
};
</script>