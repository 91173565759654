<template>
    <transition name="cookie-consent-transition">
        <div
            v-if="showBanner"
            class="fixed bg-white z-50 py-6 bottom-0 w-full border-t-2 border-gray-200"
        >
            <div
                class="flex gap-x-10 items-center justify-center"
            >
                <div>
                    This website uses cookies. By continuing to use this site, you accept our use of cookies, 
                    <router-link 
                        to="/terms-and-conditions"
                        class="underline px-1"
                        target="_blank"
                    >
                        terms and conditions
                    </router-link>
                    and 
                    <router-link 
                        to="/privacy-policy"
                        class="underline px-1"
                        target="_blank"
                    >
                        privacy policy
                    </router-link>
                </div>
                <button
                    class="pointer text-black px-5 py-2 bg-white border-2 border-black"
                    @click="dismiss"
                >
                    Accept & Close
                </button>
            </div>
        </div>
    </transition>
</template>
  
<script>
export default {
    name: 'CookieConsent',
    data() {
        return {
            cookieValue: 'accepted',
            cookie: null,
            cookieName: 'cookieconsent_status',
            cookieExpiryDays: 365
        }
    },
    computed:{
        showBanner() {
            return !this.cookie || this.cookie !== this.cookieValue;
        }
    },
    beforeMount() {
        this.cookie = this.$cookie.get(this.cookieName);
    },
    methods: {
        dismiss() {
            this.cookie = this.cookieValue;

            const exdate = new Date();
            exdate.setDate(exdate.getDate() + (this.cookieExpiryDays || 365));

            const domain = process.env.NODE_ENV === 'production' ? 'ibizace.com' : 'localhost';
            this.$cookie.set(
                this.cookieName,
                this.cookieValue,
                {
                    domain,
                    secure: true,
                    path: '/',
                    samesite: 'Strict',
                    expires: exdate.toUTCString()
                }
            );
        }
    }
}
</script>