<template>
    <ul class="w-full flex">
        <li 
            class="w-1/2 py-1 cursor-pointer border-b-2 font-medium text-sm md:text-base"
            :class="{
                'border-white text-white': activeTab === 0, 
                'text-gray-400 border-gray-500': activeTab === 1
            }"
            @click="change(0)"
        >
            Product Description
        </li>
        <li 
            class="w-1/2 py-1 cursor-pointer border-b-2 font-medium text-sm md:text-base"
            :class="{
                'border-white text-white': activeTab === 1, 
                'text-gray-400 border-gray-500': activeTab === 0
            }"
            @click="change(1)"
        >
            Company Description
        </li>
    </ul>
</template>
<script>
export default {
    name: 'WebshopItemDescriptionNavigatorComponent',
    props: {
        activeTab: {
            type: Number,
            required: true,
            default: 0
        }
    },
    methods: {
        change(tab) {
            this.$emit('change', tab);
        }
    }
};
</script>