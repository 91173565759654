<template>
    <section class="w-full bg-darkBlack">
        <div class="py-10 gap-y-8 flex flex-col md:container md:mx-auto md:px-10 md:py-20 md:gap-y-20">
            <h2 class="text-white font-bold text-lg text-center md:text-4xl">
                What our
                <span class="md:text-yellow">club members</span>
                say
            </h2>
            <div 
                id="testimonials"
                class="w-full overflow-x-hidden px-10 py-2 flex"
            >
                <div 
                    v-for="(i, index) in items"
                    :id="`testimonials-${index}`"
                    :key="i.order"
                    class="text-white flex-none w-screen md:w-1/2"
                >
                    <div
                        class="w-screen grid grid-rows-2 gap-x-6 gap-y-6 p-4 md:w-full"
                    >
                        <div class="flex gap-x-8">
                            <img 
                                :src="imagePath(i)"
                                class="object-fit w-20 h-20"
                            />
                            <div class="flex flex-col text-sm md:text-base gap-y-1">
                                <span class="text-lg md:text-xl">
                                    {{ i.name }}
                                </span>
                                <span>
                                    {{ i.role }}
                                </span>
                                <span>
                                    {{ i.location }}
                                </span>
                            </div>
                        </div>
                        <div class="text-xs md:text-base">
                            "{{ i.text }}"
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="text-yellow flex text-base items-center justify-center gap-x-6 md:gap-x-10 md:text-2xl md:justify-end"
            >
                <button
                    class="border-2 border-yellow items-center w-8 h-10 md:w-14 md:h-16"
                    @click="left"
                >
                    <font-awesome-icon 
                        :icon="['fas', 'angle-left']" 
                        class="font-semibold"
                    />
                </button>
                <div>
                    {{ currentItem + 1 }} / 
                    {{ items.length }}
                </div>
                <button
                    class="border-2 border-yellow items-center w-8 h-10 md:w-14 md:h-16"
                    @click="right"
                >
                    <font-awesome-icon 
                        :icon="['fas', 'angle-right']" 
                        class="font-semibold"
                    />
                </button>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'TestimonialsComponent',
    data() {
        return {
            items: require('@/assets/testimonials.json'),
            currentItem: 0
        };
    },
    computed: {
        _itemSize() {
            const container = document.querySelector('#testimonials');
            return Math.ceil(container.scrollWidth / this.items.length);
        }
    },
    methods: {
        imagePath(item) {
            return `${process.env.VUE_APP_MEDIA_PATH}testimonials/${item.image}`;
        },
        left() {
            if (this.currentItem === 0) return;
            this.currentItem -= 1;
            this._scroll();
        },
        right() {
            if (this.currentItem === this.items.length - 1) return;
            this.currentItem += 1;
            this._scroll();
        },
        _scroll() {
            const scrollTo = document.querySelector(`#testimonials-${this.currentItem}`);
            scrollTo.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    }
};
</script>